import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import {runUserActions} from "../utils/user-actions";

// ----------------------------------------------------------------------

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null
    })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    method: "jwt",
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve()
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node
};

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem("accessToken");

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    const response = await axios.get("/auth/me/");
                    const user = response.data;
                    runUserActions(user);
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: "INITIALIZE",
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: "INITIALIZE",
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialize();
    }, []);

    const login = async (phone, password) => {
        const response = await axios.post("/auth/login/", {
            phone,
            password
        });
        const { token, user } = response.data;
        setSession(token.access);
        runUserActions(user);
        dispatch({
            type: "LOGIN",
            payload: {
                user
            }
        });
    };

    const register = async (phone, password, password2) => {
        const response = await axios.post("/auth/register/", {
            phone,
            password,
            password2
        });
        const { token, user } = response.data;
        setSession(token.access);
        runUserActions(user);
        dispatch({
            type: "LOGIN",
            payload: {
                user
            }
        });
    };

    const logout = async () => {
        await axios.post("/auth/logout/");
        setSession(null);
        runUserActions(null);
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                login,
                register,
                logout
            }}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };
