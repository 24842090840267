import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import useAuth from "../hooks/useAuth";

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const {isAuthenticated, isInitialized} = useAuth();

    if (isInitialized && !isAuthenticated) {
        return <Navigate to='/'/>;
    }

    return <>{children}</>;
}
