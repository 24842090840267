import {createSlice} from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import {dispatch} from '../store';

const initialState = {
    products: null,
    count: null
};


const slice = createSlice({
    name: 'products', initialState, reducers: {
        getProductsSuccess(state, action) {
            state.products = action.payload.results;
            state.count = action.payload.count;
        },

        createProductSuccess(state, action) {
            state.products.push(action.payload);
            state.count += 1;
        },

        editProductSuccess(state, { payload }) {
            const index = state.products.findIndex((product) => product.id === payload.id);
            if (index !== -1) {
                state.products[index] = payload;
            }
        },

        deleteProductSuccess(state, action) {
            state.products = state.products.filter((product) => product.id !== action.payload);
            state.count -= 1;
        },

        hasError(state, action) {
            state.products = null;
            state.count = null;
        }
    },
});

// Reducer
export default slice.reducer;

export function getProducts(params) {
    return async () => {
        try {
            const response = await axios.get('/products/', {
                params,
            });
            dispatch(slice.actions.getProductsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

async function saveAttachment(file) {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post('/attachments/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function createProduct({ name, price, description, file, category }) {
    return async () => {
        try {
            const attachmentResponse = await saveAttachment(file);
            const params = {name, price, description, image: attachmentResponse.data.id, category}
            const productResponse = await axios.post('/products/create/', params);
            dispatch(slice.actions.createProductSuccess(productResponse.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editProduct(id, { name, price, description, file, category }) {
    return async () => {
        try {
            let params = {name, price, description, category};
            if (file && typeof file === "object") {
                const attachmentResponse = await saveAttachment(file);
                params.image = attachmentResponse.data.id;
            } else {
                params.image = file;
            }
            const productResponse = await axios.put(`/products/edit/${id}/`, params);
            dispatch(slice.actions.editProductSuccess(productResponse.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteProduct(id) {
    return async () => {
        try {
            await axios.post(`/products/delete/${id}/`);
            dispatch(slice.actions.deleteProductSuccess(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}