import React from "react";
import {APP_NAME} from "utils/config";

export default function Slider() {
    return <div className="slider__area slider--three">
        <div className="slider__activation--1">
            <div className="slide slider__fixed--height bg-image--11 poss--relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="slider__content">
                                <div className="slider__inner">
                                    <h1 style={{'fontSize': '6vw'}}>{APP_NAME}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}