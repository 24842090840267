import {dispatch, useSelector} from 'redux/store';
import {getUsers} from 'redux/slices/users';
import {useEffect, useState} from "react";
import {ITEMS_PER_PAGE} from "utils/config";
import Table from "../../components/Table";

export default function AdminUsers() {
    const {users, count} = useSelector((state) => state.users);
    const [page, setPage] = useState(1);
    const rowsPerPage = ITEMS_PER_PAGE;

    const tableConfig = [{
        "key": "id", "name": "ID",
    }, {
        "key": "phone", "name": "Հեռախոսահամար",
    }]

    useEffect(() => {
        dispatch(getUsers({
            limit: rowsPerPage,
            offset: (page - 1) * rowsPerPage
        }));
    }, [page, rowsPerPage]);

    return <>
        <h1>Օգտատերեր</h1>
        <Table list={users}
               page={page} onPageChange={setPage}
               rowsPerPage={rowsPerPage}
               count={count}>
            <thead>
            <tr>
                {tableConfig.map((config, index) => {
                    return <th key={index}>{config.name}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {users?.map((user, index) => {
                return <tr key={index}>
                    {tableConfig.map((config, index) => {
                        return <td key={index}>{user[config.key]}</td>
                    })}
                </tr>
            })}
            </tbody>
        </Table>
    </>
}