
import { createSlice } from '@reduxjs/toolkit';
import axios from "../../utils/axios";
import {dispatch} from "../store";

const initialState = {
    cart: null,
    error: null,
    cartCount: null
};

const getCount = (cart) => {
    return cart.cart_items.reduce((acc, item) => acc + item.quantity, 0);
}

const getTotals = (cart) => {
    let subtotal = cart.cart_items.reduce((acc, item) => acc + item.product.price * item.quantity, 0);
    return {
        subtotal,
        total: subtotal + cart.delivery

    }
}

const slice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        resetCart(state, action) {
            state.cart = null;
            state.cartCount = null;
        },
        addCart(state, action) {
            state.cart = action.payload;
            state.cartCount = getCount(state.cart);
            state.cart = {...state.cart, ...getTotals(state.cart)};
        },
        addToCartSuccess(state, {payload}) {
            const index = state.cart.cart_items.findIndex(item => item.id === payload.id);
            if (index === -1) {
                state.cart.cart_items.push(payload);
            } else {
                state.cart.cart_items[index] = payload;
            }
            state.cartCount = getCount(state.cart);
            state.cart = {...state.cart, ...getTotals(state.cart)};
        },
        removeFromCartSuccess(state, { payload }) {
            const indexToRemove = state.cart.cart_items.findIndex(item => item.id === payload.id);
            if (indexToRemove !== -1) {
                state.cart.cart_items.splice(indexToRemove, 1);
                state.cartCount = getCount(state.cart);
                state.cart = {...state.cart, ...getTotals(state.cart)};
            }
        },
        hasError(state, {payload}) {
            state.error = payload;
        }
    },
});

export const {addCart, resetCart} = slice.actions;

export default slice.reducer;

export function addToCart(product_id, quantity) {
    const params = {product_id, quantity}

    return async () => {
        try {
            const response = await axios.post('/cart/add/', params);
            dispatch(slice.actions.addToCartSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeFromCart(id) {
    return async () => {
        try {
            await axios.delete(`/cart/cart-item/${id}`);
            dispatch(slice.actions.removeFromCartSuccess({id}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}