import {createProduct, editProduct} from 'redux/slices/products';
import {dispatch} from "redux/store";
import {useNavigate, useParams} from 'react-router-dom';
import {useCallback, useEffect} from "react";
import {useAsyncFetch} from "utils/useAsyncFetch";

export default function AdminCreateProduct() {
    const {id} = useParams();
    const navigate = useNavigate();

    const {
        fetchData: fetchProductData,
        data: product,
    } = useAsyncFetch(`/products/${id}/`);

    const {
        fetchData: fetchCategoriesData,
        data: categories,
    } = useAsyncFetch(`/categories/all/`);

    useEffect(() => {
        if (id) {
            fetchProductData();
        }
        fetchCategoriesData()
    }, [id, fetchProductData,fetchCategoriesData]);

    const onSubmit = useCallback(event => {
        event.preventDefault();

        const name = event.target.name.value;
        const price = event.target.price.value;
        const description = event.target.description.value;
        const files = event.target.image.files
        const category = event.target.category.value ? event.target.category.value : null;
        const file = (files && files[0]) || product.image.id;
        const params = {name, price, description, file, category}
        dispatch(id ? editProduct(id, params) : createProduct(params))
        navigate(-1);
    }, [product?.image.id, id, navigate])

    return (!id || product) && <>
        <h1>{id ? 'Խմբագրել' : 'Ստեղծել'} ապրանք</h1>
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label htmlFor="name">Անուն:</label>
                <input type="text" className="form-control" id="name" name="name" required
                       defaultValue={product?.name}/>
            </div>
            <div className="form-group">
                <label htmlFor="price">Գին:</label>
                <input type="number" className="form-control" id="price" name="price" required
                       defaultValue={product?.price}/>
            </div>
            <div className="form-group">
                <label htmlFor="description">Նկարագրություն:</label>
                <textarea className="form-control" id="description" name="description" rows="4" required
                          defaultValue={product?.description}></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="description">Կատեգորիա:</label>
                <select className="form-control" id="category" name="category" defaultValue={product?.category?.id}>
                    <option value={null}></option>
                    {categories?.map((category, index) => {
                        return <option key={index} value={category.id}>{category.name}</option>
                    })}
                </select>
            </div>
            <div className="form-group">
            <label htmlFor="image">Նկար:</label>
                <input type="file" className="form-control-file" id="image" name="image" accept="image/*"
                       required={!id}/>
            </div>
            <button type="submit" className="btn btn-karkandak">{id ? 'Խմբագրել' : 'Ստեղծել'}</button>
        </form>
    </>
}