import "./template/css/bootstrap.min.css"
import "./template/css/plugins.css"
import "./template/style.css"
import React, {Component} from "react";
import Router from "./router/router";
import {BrowserRouter} from "react-router-dom";
import wow from 'wow.js';
import ScrollReveal from "scrollreveal";
import {APP_NAME} from "./utils/config";

window.WOW = wow
window.ScrollReveal = ScrollReveal

export default class App extends Component {
    componentDidMount() {
        if (this.runOnce) {
            return
        } else {
            this.runOnce = true
        }
        ["js/plugins.js", "js/active.js"].forEach(url => {
            const script = document.createElement("script");
            script.async = false;
            script.src = process.env.PUBLIC_URL + '/' + url;
            document.body.appendChild(script);
        })
    }

    render() {
        return <BrowserRouter>
            <title>{APP_NAME}</title>
            <Router/>
        </BrowserRouter>;
    }
}
