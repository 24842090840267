import {MEDIA_URL} from "utils/config";
import {removeFromCart} from "redux/slices/cart";
import {dispatch} from "redux/store";

export default function CartItem({ item }) {
    const remove = (id) => {
        dispatch(removeFromCart(id))
    }

    return <div className="cartbox__item">
        <div className="cartbox__item__thumb">
            <img width={75} height={75} src={MEDIA_URL + item.product.image.file}
                 alt="small thumbnail" style={{objectFit: 'cover'}}/>
        </div>
        <div className="cartbox__item__content">
            <h5 style={{color: "#000"}}>{item.product.name}</h5>
            <p>Քանակ: <span>{item.quantity}</span></p>
            <span className="price">{item.product.price}֏</span>
        </div>
        <button className="cartbox__item__remove" onClick={() => remove(item.id)}>
            <i className="fa fa-trash"></i>
        </button>
    </div>
}