import 'react-phone-number-input/style.css'
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import {useState} from "react";
import OverlayBox from "../../components/OverlayBox";

export default function Account() {
    const [isLoginTab, setLoginTab] = useState(true);

    return <OverlayBox>
        <ul className="nav accountbox__filters" id="myTab" role="tablist">
            <li onClick={() => setLoginTab(true)}>
                <a className="active" id="log-tab" data-bs-toggle="tab" href="#log" role="tab"
                   aria-controls="log" aria-selected="true">Մուտք</a>
            </li>
            <li onClick={() => setLoginTab(false)}>
                <a id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                   aria-controls="profile" aria-selected="false">Գրանցվել</a>
            </li>
        </ul>
        <div className="accountbox__inner tab-content" id="myTabContent">
            <div className="accountbox__login tab-pane fade show active" id="log" role="tabpanel"
                 aria-labelledby="log-tab">
                {isLoginTab && <LoginForm/>}
            </div>
            <div className="accountbox__register tab-pane fade" id="profile" role="tabpanel"
                 aria-labelledby="profile-tab">
                {!isLoginTab && <RegisterForm/>}
            </div>
        </div>
    </OverlayBox>
}