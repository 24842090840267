import {useCallback, useEffect, useState} from "react";
import {ITEMS_PER_PAGE} from "utils/config";
import Table from "../../components/Table";
import {useAsyncFetch} from "utils/useAsyncFetch";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

export default function AdminCategories() {
    const {
        fetchData,
        data,
    } = useAsyncFetch(`/categories/list/`);
    const [page, setPage] = useState(1);
    const rowsPerPage = ITEMS_PER_PAGE;
    const navigate = useNavigate()

    const tableConfig = [{
        "key": "id", "name": "ID",
    }, {
        "key": "name", "name": "Անուն",
    }]

    useEffect(() => {
        fetchData({
            offset: (page - 1) * rowsPerPage,
            limit: rowsPerPage
        })
    }, [fetchData, page, rowsPerPage]);

    const onEditClick = useCallback(category => {
        navigate(`edit/${category.id}`)
    }, [navigate])

    return <>
        <div className="d-flex justify-content-between align-items-center">
            <h1>Կատեգորիաներ</h1>
            <Link to="./create"><h3>Ավելացնել</h3></Link>
        </div>
        <Table list={data?.results}
               page={page} onPageChange={setPage}
               rowsPerPage={rowsPerPage}
               count={data?.count}>
            <thead>
            <tr>
                {tableConfig.map((config, index) => {
                    return <th key={index}>{config.name}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {data?.results.map((item, index) => {
                return <tr key={index}>
                    {tableConfig.map((config, index) => {
                        return <td key={index}>{item[config.key]}</td>
                    })}
                    <td>
                        <FontAwesomeIcon style={{cursor: "pointer"}} icon={faEdit} onClick={() => onEditClick(item)}/>
                    </td>
                </tr>
            })}
            </tbody>
        </Table>
    </>
}