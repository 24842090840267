import {dispatch} from "../redux/store";
import {addCart, resetCart} from "../redux/slices/cart";

const runUserActions = (user) => {
    setupCart(user);
};

const setupCart = (user) => {
    if (user) {
        dispatch(addCart(user.cart))
    } else {
        dispatch(resetCart())
    }
}

export {runUserActions};
