export const HOST = process.env.REACT_APP_HOST || "";
export const HOST_API = process.env.REACT_APP_HOST_API || "";
export const APP_NAME = process.env.REACT_APP_APP_NAME || "";
export const HAS_CREDIT_CART_PAYMENT = process.env.REACT_APP_HAS_CREDIT_CART_PAYMENT === 'true';
export const SHOW_SLIDER = process.env.REACT_APP_SHOW_SLIDER === 'true';
export const PRODUCT_TITLE = process.env.REACT_APP_PRODUCT_TITLE;
export const ADDRESS = process.env.REACT_APP_ADDRESS || "";
export const PHONE_NUMBER = process.env.REACT_APP_PHONE_NUMBER || "";
export const EMAIL = process.env.REACT_APP_EMAIL || "";
export const FACEBOOK = process.env.REACT_APP_FACEBOOK || "";
export const MEDIA_URL = `${HOST_API}media/`;
export const AMERIABANK_URL = process.env.REACT_APP_AMERIABANK_URL || "";
export const WORKING_HOURS = process.env.REACT_APP_WORKING_HOURS || "";
export const ITEMS_PER_PAGE = 10;

export const PAYMENT_METHODS = {
    credit_card: 'Կրեդիտ քարտ',
    cash: 'Կանխիկ'
}

export const ORDER_STATUSES = {
    waiting_for_payment: 'Սպասում է վճարման',
    created: 'Ստեղծված',
    in_progress: 'Ընթացիկ',
    cancelled: 'Չեղարկված',
    done: 'Ավարտված',
};

export const ORDER_STATUS_COLORS = {
    waiting_for_payment: '#f0f0f0',
    created: '#fff9c4',
    in_progress: '#c8e6c9',
    cancelled: '#ffebee',
    done: '#ffffff',
};

export const PHONE_NUMBER_REGEX = /^\+\d{8,15}$/