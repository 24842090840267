import React, {useCallback, useEffect, useMemo} from "react";
import {dispatch, useSelector} from "redux/store";
import {getProducts} from "redux/slices/products";
import Product from "./Product";
import {PRODUCT_TITLE} from "utils/config";
import {useAsyncFetch} from "utils/useAsyncFetch";
import {useSearchParams} from "react-router-dom";

const categoriesMapper = (categories) => {
    if (categories.length) {
        return [{
            id: null, name: 'Բոլոր'
        }, ...categories]
    } else {
        return null;
    }
}

export default function Products() {
    const {products} = useSelector((state) => state.products);
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCategoryId = useMemo(() => searchParams.has('category') ? +searchParams.get('category') : null, [searchParams])

    const {
        fetchData, data: categories,
    } = useAsyncFetch(`/categories/all/`, categoriesMapper);

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {
        dispatch(getProducts({limit: 100, offset: 0, category: selectedCategoryId}));
    }, [selectedCategoryId])

    const onCategoryClick = useCallback((category) => {
        setSearchParams(category?.id ? {category: category.id} : {});
    }, [setSearchParams])

    return <section className="food__special__offer bg--white">
        <div className="container mt--30 mb--50">
            {PRODUCT_TITLE && <h2 style={{color: "#000"}}>{PRODUCT_TITLE}</h2>}
            {categories && <div className="food__nav nav nav-tabs" role="tablist">
                {categories.map((category, index) => <div
                    className={selectedCategoryId === category.id ? 'active' : ''}
                    key={index}
                    data-bs-toggle="tab"
                    onClick={() => onCategoryClick(category)}
                    role="tab">{category.name}</div>)}
            </div>}
            <div className="row">
                {products?.map((product, index) => <Product product={product} key={index}/>)}
            </div>
        </div>
    </section>

}