import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "../../utils/axios";
import {ORDER_STATUSES, PAYMENT_METHODS} from "utils/config";
import {formatForPreview} from "utils/format";

export default function AdminViewOrder() {
    const {orderId} = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        const getOrder = async () => {
            try {
                const response = await axios.get(`/orders/${orderId}/`);
                setOrder(response.data);
            } catch (e) {
            }
        }
        getOrder()
    }, [orderId]);

    return order && <div style={{color: "#000"}}>
        <h1>Պատվեր {order.id}</h1>
        <div className="card">
            <div className="card-body">
                <dl className="row">
                    <dt className="col-sm-3">Կարգավիճակ:</dt>
                    <dd className="col-sm-9">{ORDER_STATUSES[order.status]}</dd>

                    <dt className="col-sm-3">Առաքման ժամանակ:</dt>
                    <dd className="col-sm-9">{formatForPreview(order.delivery_time)}</dd>

                    <dt className="col-sm-3">Վճարման եղանակ:</dt>
                    <dd className="col-sm-9">{PAYMENT_METHODS[order.payment_method]}</dd>

                    <dt className="col-sm-3">Ենթագումար:</dt>
                    <dd className="col-sm-9">{order.cart.subtotal}֏</dd>

                    <dt className="col-sm-3">Առաքման վճար:</dt>
                    <dd className="col-sm-9">{order.cart.delivery}֏</dd>

                    <dt className="col-sm-3">Ընդամենը:</dt>
                    <dd className="col-sm-9">{order.cart.total}֏</dd>
                </dl>
            </div>
        </div>
        <div className="card" style={{marginTop: '30px'}}>
            <div className="card-body">
                <h5 className="card-title">Ապրանքներ</h5>

                <dl className="row">
                    {order.cart.cart_items.map((item, index) => {
                        return <>
                            <dt className="col-sm-3" style={{marginTop: index && '20px'}}>
                                <div>{item.product.name} x{item.quantity}</div>
                                <fiv>{item.product.description}</fiv>
                            </dt>
                            <dd className="col-sm-9"
                                style={{marginTop: index && '20px'}}>{item.product.price * item.quantity}֏
                            </dd>
                        </>
                    })}
                </dl>
            </div>
        </div>
        <div className="card" style={{marginTop: '30px'}}>
            <div className="card-body">
                <h5 className="card-title">Առաքման հասցե</h5>

                <dl className="row">
                    <dt className="col-sm-3">Երկիր:</dt>
                    <dd className="col-sm-9">{order.address.country}</dd>

                    <dt className="col-sm-3">Քաղաք:</dt>
                    <dd className="col-sm-9">{order.address.city}</dd>

                    <dt className="col-sm-3">Հասցե:</dt>
                    <dd className="col-sm-9">{order.address.address_line}</dd>
                </dl>
            </div>
        </div>
        <div className="card" style={{marginTop: '30px'}}>
            <div className="card-body">
                <h5 className="card-title">Օգտատեր</h5>

                <dl className="row">
                    <dt className="col-sm-3">էլ. փոստ:</dt>
                    <dd className="col-sm-9">{order.user.email}</dd>

                    <dt className="col-sm-3">Հեռախոսահամար:</dt>
                    <dd className="col-sm-9">{order.user.phone}</dd>
                </dl>
            </div>
        </div>
    </div>
}