import {createSearchParams, useNavigate} from "react-router-dom";
import OverlayBox from "../../components/OverlayBox";
import React from "react";
import * as Yup from "yup";
import {LOCALIZATIONS} from "../../utils/localizations";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import axios from "../../utils/axios";
import {dispatch} from "../../redux/store";
import {openSnackbar} from "../../redux/slices/ui";
import {useQuery} from "../../hooks/useQuery";

export default function EnterSmsCode() {
    const navigate = useNavigate();
    const query = useQuery();

    const validationSchema = Yup.object().shape({
        verification_code: Yup.string().required(LOCALIZATIONS['This field is required.']),
    });

    const {register, handleSubmit, setValue, formState: {errors}} = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        axios.post('/phone-verification/check/', {...data, phone: query.get('phone')}).then((res) => {
            navigate({
                pathname: query.get('path'),
                search: `?${createSearchParams({verification_code: data.verification_code, phone: query.get('phone')})}`
            })
        }).catch((err) => {
            setValue('verification_code', '')
            dispatch(openSnackbar({message: 'Հաստատման կոդը սխալ է', type: 'error'}))
        })
    };

    return <OverlayBox>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h6 className='color--black'>Մուտքագրեք հաստատման կոդը</h6>
            <div className="single-input">
                <input className="cr-round--lg"
                       type={'text'}
                       placeholder="Հաստատման կոդ"
                       {...register('verification_code')}/>
                {errors.verification_code && <small className="text-danger">{errors.verification_code.message}</small>}
            </div>
            <div className="single-input">
                <button type="submit" className="food__btn"><span>Հաստատել</span></button>
            </div>
        </form>
    </OverlayBox>
}