import {Quantity} from "components/Quantity";
import React, {useRef} from "react";
import PropTypes from "prop-types";
import {dispatch, useSelector} from "redux/store";
import {addToCart} from "redux/slices/cart";
import {MEDIA_URL} from "utils/config";
import useAuth from "../../hooks/useAuth";
import {openSnackbar} from "redux/slices/ui";
import {useNavigate} from "react-router-dom";

Product.propTypes = {
    product: PropTypes.object.isRequired,
};

export default function Product({product}) {
    const {isAuthenticated} = useAuth();
    const quantityRef = useRef(null);
    const navigate = useNavigate();
    const { defaultProductCount } = useSelector((state) => state.settings);

    const add = (productId) => {
        if (isAuthenticated) {
            dispatch(addToCart(productId, quantityRef.current.quantity));
            dispatch(openSnackbar({message: 'Ապրանքն ավելացված է զամբյուղի մեջ'}));
        } else {
            navigate('/account')
        }
    }

    return <div className="col-md-6 col-sm-12 col-lg-3">
        <div className="food__offer text-center foo" style={{height: 'calc(100% - 30px)'}}>
            <div className="offer__thumb poss--relative">
                <img width="273" height="181" src={MEDIA_URL + product.image.file} alt="offer images"
                     style={{objectFit: 'cover'}} loading="lazy" />
                <div className="offer__product__prize">
                    <span>{product.price}֏</span>
                </div>
            </div>
            <div className="offer__details">
                <div>
                    <h2 style={{color: "#000"}}>{product.name}</h2>
                    <p>{product.description}</p>
                </div>
                <div>
                    <Quantity ref={quantityRef} style={{margin: "13px auto"}} initial={defaultProductCount}/>
                    <div className="offer__btn cursor-pointer">
                        <div className="food__btn grey--btn mid-height"
                             onClick={() => add(product.id)}>Ավելացնել
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}