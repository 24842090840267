import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
    users: null,
    count: null
};


const slice = createSlice({
    name: 'users', initialState, reducers: {
        getUsersSuccess(state, action) {
            state.users = action.payload.results;
            state.count = action.payload.count;
        },

        hasError(state, action) {
            state.users = null;
            state.count = null;
        }
    },
});

// Reducer
export default slice.reducer;

export function getUsers(params) {
    return async () => {
        try {
            const response = await axios.get('/user/', {
                params,
            });
            dispatch(slice.actions.getUsersSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}