import PhoneInput from "react-phone-number-input";
import React, {useState} from "react";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

export default function RegisterForm() {
    const [phone, setPhone] = useState()
    const {register} = useAuth();
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();

    const removeErrors = async (id) => {
        const e = {...errors}
        e && delete e[id]
        setErrors(e)
    }

    const onSubmit = async (event) => {
        try {
            event.preventDefault();

            const password = event.target.password.value;
            const password2 = event.target.password2.value;
            await register(phone, password, password2);

            event.target.reset()
            setErrors(null)
            navigate('/')
        } catch (error) {
            setErrors(error)
        }
    };

    return <form onSubmit={onSubmit}>
        <div className="single-input">
            <PhoneInput defaultCountry="AM"
                        className="cr-round--lg"
                        placeholder="Հեռախոսահամար"
                        value={phone}
                        onChange={(e) => {setPhone(e); removeErrors('phone')}}/>
            <small className="text-danger">
                {errors && errors['phone'] && errors['phone'][0]}
            </small>
        </div>
        <div className="single-input">
            <input id="password" className="cr-round--lg" type="password" placeholder="Գաղտնաբառ"
                   onChange={() => removeErrors('password')}/>
            <small className="text-danger">
                {errors && errors['password'] && errors['password'][0]}
            </small>
        </div>
        <div className="single-input">
            <input id="password2" className="cr-round--lg" type="password"
                   placeholder="Կրկնել գաղտնաբառը" onChange={() => removeErrors('password2')}/>
            <small className="text-danger">
                {errors && errors['password2'] && errors['password2'][0]}
            </small>
        </div>
        <div className="single-input">
            <button type="submit" className="food__btn"><span>Շարունակել</span></button>
        </div>
    </form>
}