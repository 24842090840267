import React, {forwardRef, useImperativeHandle, useState} from "react";

export const Quantity = forwardRef(({initial, style}, ref) => {
    const [quantity, setQuantity] = useState(initial);

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    useImperativeHandle(ref, () => ({
        quantity
    }), [quantity]);

    return <div className="d-flex justify-content-between" style={{...style, width: "130px"}}>
        <div className="dec qtybutton cursor-pointer" onClick={decreaseQuantity}>-</div>
        <div className="cart-plus-minus d-flex justify-content-center align-items-center">
            <div>{quantity}</div>
        </div>
        <div className="inc qtybutton cursor-pointer" onClick={increaseQuantity}>+</div>
    </div>
})