import {Link, Outlet, useLocation} from "react-router-dom";

export default function Admin() {
    const location = useLocation();
    const tabs = [{
        name: 'Գլխավոր',
        path: '/'
    }, {
        name: 'Պատվերներ',
        path: '/admin/orders'
    }, {
        name: 'Օգտատերեր',
        path: '/admin/users'
    }, {
        name: 'Ապրանքներ',
        path: '/admin/products'
    }, {
        name: 'Կատեգորիաներ',
        path: '/admin/categories'
    }]


    return <div className="container-fluid">
        <div className="row">
            <nav id="sidebar" className="col-md-3 col-lg-2 d-md-block sidebar">
                <div className="position-sticky">
                    <h2 className="sidebar-heading px-3 mt-4 mb-1 text-muted">
                        <span>Ադմին</span>
                    </h2>
                    <ul className="nav nav-pills flex-column">
                        {tabs.map((tab, index) => {
                            const isActive = location.pathname === tab.path;
                            return <li className="nav-item" key={index}>
                                <Link className={`nav-link ${isActive ? 'active' : ''}`} to={tab.path}>
                                    {tab.name}
                                </Link>
                            </li>
                        })}
                    </ul>
                </div>
            </nav>

            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 bg-light" style={{'height': '100vh'}}>
                <div className="container mt-5">
                    <Outlet/>
                </div>
            </main>
        </div>
    </div>
}