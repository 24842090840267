import {dispatch, useSelector} from 'redux/store';
import {getProducts, deleteProduct} from 'redux/slices/products';
import {useCallback, useEffect, useState} from "react";
import {ITEMS_PER_PAGE} from "utils/config";
import Table from "../../components/Table";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

export default function AdminProducts() {
    const {products, count} = useSelector((state) => state.products);
    const [page, setPage] = useState(1);
    const rowsPerPage = ITEMS_PER_PAGE;
    const navigate = useNavigate()

    const tableConfig = [{
        key: "id",
        name: "ID",
    }, {
        key: "name",
        name: "անուն",
    }, {
        key: "price",
        name: "գին",
    }, {
        key: "description",
        name: "նկարագրություն",
    }, {
        name: "կատեգորիա",
        mapper: {
            key: "category",
            value: item => item?.name
        }
    }]

    useEffect(() => {
        dispatch(getProducts({
            offset: (page - 1) * rowsPerPage,
            limit: rowsPerPage
        }));
    }, [page, rowsPerPage]);

    const onDeleteClick = useCallback(product => dispatch(deleteProduct(product.id)), [])

    const onEditClick = useCallback(product => {
        navigate(`edit/${product.id}`)
    }, [navigate])


    return <>
        <div className="d-flex justify-content-between align-items-center">
            <h1>Ապրանքներ</h1>
            <Link to="./create"><h3>Ավելացնել</h3></Link>
        </div>
        <Table list={products}
               page={page} onPageChange={setPage}
               rowsPerPage={rowsPerPage}
               count={count}>
            <thead>
            <tr>
                {tableConfig.map((config, index) => {
                    return <th key={index}>{config.name}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {products?.map((item, index) => {
                return <tr key={index}>
                    {tableConfig.map((config, index) => {
                        return <td key={index}>{config.mapper ?
                            config.mapper.value(item[config.mapper.key]) :
                            item[config.key]}</td>
                    })}
                    <td>
                        <FontAwesomeIcon style={{cursor: "pointer", marginLeft: "10px", float: 'right'}}
                                         icon={faTrash} onClick={() => onDeleteClick(item)}/>
                        <FontAwesomeIcon style={{cursor: "pointer", float: 'right'}} icon={faEdit}
                                         onClick={() => onEditClick(item)}/>
                    </td>
                </tr>
            })}
            </tbody>
        </Table>
    </>
}