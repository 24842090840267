import Home from "../home/Home";
import {Navigate, Outlet, useRoutes} from "react-router-dom";
import Admin from "../admin/Admin";
import AdminUsers from "../admin/users/AdminUsers";
import AdminProducts from "../admin/products/AdminProducts";
import AdminOrders from "../admin/orders/AdminOrders";
import AdminCreateProduct from "../admin/products/AdminCreateProduct";
import Checkout from "../home/Checkout/Checkout";
import Header from "../home/Header";
import React from "react";
import Footer from "../home/Footer";
import AdminViewOrder from "../admin/orders/AdminViewOrder";
import {Alert, Snackbar} from "@mui/material";
import {useSelector} from "redux/store";
import AuthGuard from "../guards/AuthGuard";
import AdminGuard from "../guards/AdminGuard";
import EnterSmsCode from "../home/Account/EnterSmsCode";
import Account from "../home/Account/Account";
import EnterPhone from "../home/Account/EnterPhone";
import Cart from "../home/Cart/Cart";
import EnterNewPassword from "../home/Account/EnterNewPassword";
import AdminCategories from "admin/categories/AdminCategories";
import AdminCreateCategory from "admin/categories/AdminCreateCategory";

export default function Router() {
    const {snackbar} = useSelector((state) => state.ui);

    const adminPaths = [{
        element: <Navigate to="/admin/orders" replace/>, index: true
    }, {
        path: "users", element: <AdminUsers/>
    }, {
        path: "products",
        children: [{
            path: '',
            element: <AdminProducts/>
        }, {
            path: 'create',
            element: <AdminCreateProduct/>
        }, {
            path: 'edit/:id',
            element: <AdminCreateProduct/>
        }]
    }, {
        path: "orders",
        children: [{
            path: "",
            element: <AdminOrders/>
        }, {
            path: ":orderId",
            element: <AdminViewOrder/>
        }]
    }, {
        path: "categories",
        children: [{
            path: "",
            element: <AdminCategories/>
        }, {
            path: 'create',
            element: <AdminCreateCategory/>
        }, {
            path: 'edit/:id',
            element: <AdminCreateCategory/>
        }]
    }]

    const routes = [{
        path: "/", element: <>
            <Header/>
            <Outlet/>
            <Footer/>
            <Snackbar open={snackbar.open} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert severity={snackbar.type} sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>, children: [{
            path: "/",
            element: <Home/>,
            children: [{
                path: 'enter-phone', element: <EnterPhone/>,
            }, {
                path: 'enter-sms-code', element: <EnterSmsCode/>,
            }, {
                path: 'enter-new-password', element: <EnterNewPassword/>
            }, {
                path: 'account', element: <Account/>
            }, {
                path: 'cart', element: <Cart/>
            }]
        }, {
            path: "checkout", element: <AuthGuard>
                <Checkout/>
            </AuthGuard>
        }]
    }, {
        path: "admin", element: <AuthGuard><AdminGuard><Admin/></AdminGuard></AuthGuard>, children: adminPaths
    }]

    return useRoutes(routes);
}