import {createSlice} from '@reduxjs/toolkit';
import axios from "utils/axios";
import {dispatch} from "redux/store";

const initialState = {
    minimumOrderCount: 1,
    defaultProductCount: 1
};

const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings(state, {payload}) {
            Object.assign(state, payload)
        },
    },
});

export default slice.reducer;

export async function getSettings() {
    const result = await axios.get('settings/')
    dispatch(slice.actions.setSettings({
        minimumOrderCount: result.data.minimum_order_count,
        defaultProductCount: result.data.default_product_count
    }))
}

getSettings()