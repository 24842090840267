import {createSearchParams, useNavigate} from "react-router-dom";
import OverlayBox from "../../components/OverlayBox";
import PhoneInput from "react-phone-number-input";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useForm} from "react-hook-form";
import {LOCALIZATIONS} from "../../utils/localizations";
import {PHONE_NUMBER_REGEX} from "../../utils/config";
import axios from "../../utils/axios";
import {dispatch} from "../../redux/store";
import {openSnackbar} from "../../redux/slices/ui";

export default function EnterPhone() {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        phone: Yup.string()
            .matches(PHONE_NUMBER_REGEX, LOCALIZATIONS['Invalid phone number.'])
            .required(LOCALIZATIONS['This field is required.'])
    });

    const {handleSubmit, setValue, formState: {errors}, clearErrors} = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        await axios.post('/phone-verification/generate/', data)
        navigate({
            pathname: "/enter-sms-code",
            search: `?${createSearchParams({phone: data.phone, path: '/enter-new-password'})}`
        })
        dispatch(openSnackbar({message: 'Հաստատման կոդը ուղարկվել է ձեր հեռախոսահամարին'}))
    };

    return <OverlayBox>
        <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className='color--black'>Վերականգնել գաղտնաբառը</h5>
            <div className="single-input">
                <PhoneInput defaultCountry="AM"
                            className="cr-round--lg"
                            placeholder="Հեռախոսահամար"
                            onChange={(e) => {
                                setValue('phone', e);
                                clearErrors('phone');
                            }}/>
                {errors.phone && <small className="text-danger">{errors.phone.message}</small>}
            </div>
            <div className="single-input">
                <button type="submit" className="food__btn"><span>Ուղարկել կոդը</span></button>
            </div>
        </form>
    </OverlayBox>
}